import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 14,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 16,
      prop: 'status',
      name: 'select',
      options: [],
      value: 3,
      label: i18n.t('form.common.status'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        disabled: true
      }
    }, {
      span: 16,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.common.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.common.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'redeem_code',
      name: 'input',
      label: i18n.t('form.attractions.redemptionCode'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'lon',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.longitude'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'lat',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.latitude'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'site_id',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.attractions.site'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 24,
      prop: 'description',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.descriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'description_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.descriptionEn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'short_description',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.shortDescriptionCn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'short_description_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.common.shortDescriptionEn'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'level',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.attractions.siteALevel'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 16,
      prop: 'rating',
      name: 'input',
      type: 'number',
      label: i18n.t('form.common.rating'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'city_id',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.attractions.city'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 16,
      prop: 'address',
      name: 'input',
      label: i18n.t('form.common.address'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'website',
      name: 'input',
      label: i18n.t('form.attractions.website'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'contact_number',
      name: 'input',
      label: i18n.t('form.attractions.contactNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'email',
      name: 'input',
      label: i18n.t('form.common.mail'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'operation_time',
      name: 'input',
      label: i18n.t('form.common.operationHoursCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'operation_time_en',
      name: 'input',
      label: i18n.t('form.common.operationHoursEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'visiting_time',
      name: 'input',
      type: 'number',
      label: i18n.t('form.attractions.recommendedVisitDuration'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'ticket_strategy',
      name: 'input',
      label: i18n.t('form.attractions.ticketGuideCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'ticket_strategy_en',
      name: 'input',
      label: i18n.t('form.attractions.ticketGuideEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'traffic_strategy',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.transportationGuideCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'traffic_strategy_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.transportationGuideEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'tips',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.remindersCN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'tips_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.attractions.remindersEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 16,
      prop: 'ticket_type',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.attractions.ticketType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 16,
      prop: 'best_price_f',
      name: 'input',
      label: i18n.t('form.attractions.bestPrice'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visibleChange: [
          {ticket_type: 1}
        ]
      }
    }, {
      span: 24,
      prop: 'prefer_map',
      value: false,
      name: 'switch',
      label: i18n.t('form.attractions.mapMode'),
      Object: {}
    }, {
      span: 16,
      prop: 'categories',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.attractions.siteCategories'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        multiple: true,
        'collapse-tags': true
      }
    }, {
      span: 16,
      prop: 'global_labels',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.product.globalLabels'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        multiple: true,
        'collapse-tags': true
      }
    }]
  }, {
    title: i18n.t('form.attractions.specialLabel'),
    name: 1,
    createDateItem: [{
      span: 24,
      prop: 'labels_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.attractions.label'),
      Object: {
        delete: '_destroy'
      },
      createDateFirstItem: [{
        span: 16,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.common.cnName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 16,
        prop: 'name_en',
        name: 'input',
        label: i18n.t('form.common.enName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }]
    }]
  },
  // , {
  //   title: i18n.t('form.attractions.audioFiles'),
  //   name: 3,
  //   createDateItem: [{
  //     span: 24,
  //     prop: 'audio_files_attributes',
  //     name: 'array',
  //     value: [],
  //     label: i18n.t('form.attractions.audioFiles'),
  //     Object: {
  //       delete: '_destroy'
  //     },
  //     createDateFirstItem: [{
  //       span: 16,
  //       prop: 'name',
  //       name: 'input',
  //       label: i18n.t('form.common.name'),
  //       placeholder: i18n.t('placeholder.defaultInput'),
  //       Object: {
  //         maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
  //       }
  //     }, {
  //       span: 16,
  //       prop: 'language',
  //       name: 'select',
  //       options: [],
  //       value: [],
  //       label: i18n.t('form.common.language'),
  //       placeholder: i18n.t('placeholder.defaultSelect'),
  //       Object: {
  //         filterable: true
  //       }
  //     }, {
  //       span: 24,
  //       prop: 'remote_file_url',
  //       name: 'image',
  //       value: [],
  //       label: i18n.t('form.common.file'),
  //       placeholder: i18n.t('placeholder.defaultInput'),
  //       Object: {
  //         limit: 1,
  //         uploadSize: 50,
  //         uploadType: ['audio/x-m4a', 'audio/mp3', 'audio/wav']
  //       }
  //     }, {
  //       span: 24,
  //       prop: 'description',
  //       name: 'input',
  //       type: 'textarea',
  //       label: i18n.t('form.common.descriptionCn'),
  //       placeholder: i18n.t('placeholder.defaultInput'),
  //       Object: {
  //         maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
  //       }
  //     }]
  //   }]
  // },
  {
    title: i18n.t('form.common.image'),
    name: 2,
    createDateItem: [{
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      value: [],
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        limit: 100,
        imageName: 'remote_image_url'
      }
    }]
  }, {
    title: i18n.t('form.attractions.subsite'),
    name: 3,
    createDateItem: [{
      span: 24,
      prop: 'sub_sites_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.attractions.subsite'),
      Object: {
        delete: '_destroy'
      },
      createDateFirstItem: [{
        span: 16,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.common.cnName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 16,
        prop: 'name_en',
        name: 'input',
        label: i18n.t('form.common.enName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 16,
        prop: 'group_name',
        name: 'input',
        label: i18n.t('form.attractions.groupName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 16,
        prop: 'rating',
        name: 'input',
        label: i18n.t('form.common.rating'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 16,
        prop: 'lon',
        name: 'input',
        label: i18n.t('form.common.longitude'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 16,
        prop: 'lat',
        name: 'input',
        label: i18n.t('form.common.latitude'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 24,
        prop: 'description',
        name: 'input',
        label: i18n.t('form.common.descriptionCn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 24,
        prop: 'description_en',
        name: 'input',
        label: i18n.t('form.common.descriptionEn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 24,
        prop: 'images_attributes',
        name: 'image',
        value: [],
        label: i18n.t('form.common.image'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          limit: 100,
          imageName: 'remote_image_url'
        }
      }
      // , {
      //   span: 24,
      //   prop: 'audio_files_attributes',
      //   name: 'array',
      //   value: [],
      //   label: i18n.t('form.attractions.fileAttachment'),
      //   Object: {
      //     delete: '_destroy'
      //   },
      //   createDateFirstItem: [{
      //     span: 12,
      //     prop: 'name',
      //     name: 'input',
      //     label: i18n.t('form.common.name'),
      //     placeholder: i18n.t('placeholder.defaultInput'),
      //     Object: {
      //       maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      //     }
      //   }, {
      //     span: 12,
      //     prop: 'language',
      //     name: 'select',
      //     options: [],
      //     value: [],
      //     label: i18n.t('form.common.language'),
      //     placeholder: i18n.t('placeholder.defaultSelect'),
      //     Object: {
      //       filterable: true
      //     }
      //   }, {
      //     span: 24,
      //     prop: 'remote_file_url',
      //     name: 'image',
      //     value: [],
      //     label: i18n.t('form.common.file'),
      //     placeholder: i18n.t('placeholder.defaultInput'),
      //     Object: {
      //       limit: 1,
      //       uploadSize: 50,
      //       uploadType: ['audio/x-m4a', 'audio/mp3', 'audio/wav']
      //     }
      //   }, {
      //     span: 24,
      //     prop: 'description',
      //     name: 'input',
      //     type: 'textarea',
      //     label: i18n.t('form.common.descriptionCn'),
      //     placeholder: i18n.t('placeholder.defaultInput'),
      //     Object: {
      //       maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      //     }
      //   }]
      // }
      ]
    }]
  }]
}
